import React from "react";
import Helmet from "react-helmet";
import Img from "gatsby-image"

import Layout from '../components/layout';

class Homepage extends React.Component {
    render() {
        const siteTitle = "S-Tronics | Hardware Dev Expert";

        const { data } = this.props;

        return (
            <Layout>
                <Helmet title={siteTitle} />

                <section id="services" className="main style1 special">
                    <div className="grid-wrapper">
                        <div className="col-12">
                            <header className="major">
                                <h2>Need help with aspects of your electronics development?</h2>
                            </header>
                            <p>Add some firepower to your engineering team</p>
                        </div>

                        <div className="col-4">
                            <span className="image fit"><Img fluid={data.electronics.childImageSharp.fluid} alt="" /></span>
                            <h3>Electronics Design</h3>
                            <p>get expertise in the RF, analog and high speed digital domains as well as power supplies and battery-powered applications</p>
                        </div>
                        <div className="col-4">
                            <span className="image fit"><Img fluid={data.embedded.childImageSharp.fluid} alt="" /></span>
                            <h3>Embedded Applications</h3>
                            <p>build applications for Linux, Raspberry Pi, Arduino and other micro-controller host devices</p>
                        </div>
                        <div className="col-4">
                            <span className="image fit"><Img fluid={data.circuits.childImageSharp.fluid} alt="" /></span>
                            <h3>PCB Layout</h3>
                            <p> get all files required for manufacturing and full 3D models of circuit card assemblies for integration in mechanical CAD </p>
                        </div>

                    </div>
                </section>

                <section id="two" className="main style2">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <ul className="major-icons">
                                <li><span className="icon style1 major fa-code"></span></li>
                                <li><span className="icon style2 major fa-bolt"></span></li>
                                <li><span className="icon style3 major fa-mobile"></span></li>
                                <li><span className="icon style4 major fa-plug"></span></li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <header className="major">
                                <h2>Need a complete, turn-key<br />
                                hardware solution for your product?</h2>
                            </header>
                            <p>If your team doesn’t have the bandwidth to handle a new project, 
                              let S-Tronics do all of the dirty work. We can help plan your project, 
                              build proofs-of-concept and even coordinate with contract manufacturers 
                              to get your product built. We also have a broad network of engineers from 
                              various disciplines that we can work with to tackle more than just the electronics.</p>
                            <p>Engage with us early in your project cycle and we can be your complete turn-key solution. </p>
                        </div>
                    </div>
                </section>

                <section className="main style1">
                    <div className="grid-wrapper">
                        <div className="col-6">
                            <header className="major">
                                <h2>Meet Bobby Smith,<br />
                                a seasoned Electrical Engineer</h2>
                            </header>
                            <p>Bobby works hard with clients to find solutions to their problems,
                            even when they don't yet know what they need. With extensive experience
                            designing RF electronics in both the commercial and military markets, he
                            has been involved in all phases of product creation and development --
                            including initial concepts, proposals, system design, schematic capture,
                            build, debug and test. Bobby earned a BSEE from Northern Illinois University
                            in 2005 and an MSE from Purdue University in 2012.</p>
                        </div>
                        <div className="col-6">
                            <Img className="image fit" fluid={data.bio.childImageSharp.fluid}/>
                        </div>
                    </div>
                </section>


                <section id="four" className="main style2 special">
                    <div className="container">
                        <header className="major">
                            <h2>Get in touch</h2>
                        </header>
                        <p>Tell S-Tronics about your hardware needs.</p>
                        <ul className="actions uniform">
                            <li><a href="tel:+12606025756" className="button">260.602.5756</a></li>
                            <li><a href="mailto:bobby@s-tronics.com" className="button special">bobby@s-tronics.com</a></li>
                        </ul>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default Homepage;

export const pageQuery = graphql`
query {
    bio: file(relativePath: { eq: "bobby-smith.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 610, quality: 70) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    embedded: file(relativePath: { eq: "embedded.jpg" }) {
        childImageSharp {
            fluid(
                maxWidth: 860,
                maxHeight: 493,
                duotone: { highlight: "#6b88e6", shadow: "#1c2541" }
                quality: 70 ) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    electronics: file(relativePath: { eq: "electronics.jpg" }) {
        childImageSharp {
            fluid(
                maxWidth: 860,
                maxHeight: 493,
                duotone: { highlight: "#6b88e6", shadow: "#1c2541" }
                quality: 70 ) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    circuits: file(relativePath: { eq: "circuits-2.jpg" }) {
        childImageSharp {
            fluid(
                maxWidth: 860,
                maxHeight: 493,
                duotone: { highlight: "#6b88e6", shadow: "#1c2541" }
                quality: 70 ) {
                ...GatsbyImageSharpFluid
            }
        }
    }
}
`;
